import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"
import image1 from "../../../images/documentation/delete-cycle-1.png"
import image2 from "../../../images/documentation/delete-cycle-2.png"

const DeleteTestCycle = () => {
  return (
    <LayoutComponent>
      <h2>Deleting a Test Cycle</h2>

      <p>
        To maintain a clean and organized test environment, you may need to
        delete outdated or unnecessary test cycles. Follow the steps below to
        remove a test cycle and all its associated data.
      </p>

      <div className="testimonials">
        <h5>Note</h5>
        <p>
          Test cycles with dependencies cannot be deleted. Make sure the test
          cycle is not in use or associated with other components before
          proceeding.
        </p>
      </div>

      <h5>Steps to Delete a Test Cycle</h5>
      <ol>
        <li>
          Go to the <strong>Test Cycles</strong> view.
        </li>
        <li>Select the test cycle you want to delete.</li>
        <li>
          Click the <strong>Delete</strong> button.
          <br />
          <img width="100%" src={image1} alt="Delete button" />
        </li>
        <li>
          A confirmation box will appear, detailing the consequences of
          deletion. This will include the removal of all data associated with
          the test cycle. Review the information carefully and confirm if you
          wish to proceed.
          <br />
          <img width="40%" src={image2} alt="Confirmation box" />
        </li>
        <div className="testimonials">
          <h5>Note</h5>
          <p>
            This action cannot be undone. Ensure that you have reviewed all
            associated data before confirming the deletion.
          </p>
        </div>
        <li>
          Once confirmed, the test cycle and all associated data will be
          permanently deleted.
        </li>
      </ol>
    </LayoutComponent>
  )
}

export default DeleteTestCycle
